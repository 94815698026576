import React, { useEffect } from 'react';
import { globalHistory } from '@reach/router'
import Generic from 'components/Layouts/Generic';
import gtmEvents from '../utils/functions/gtmFunctions.js';
const title = 'À propos';

const body = `
  <p>Conçue et diffusée par Bibliothèque et Archives nationales du Québec (BAnQ),  la plateforme Web éducative Les Lignes du temps du Québec vise à mettre en valeur des documents conservés dans les collections de BAnQ, en lien avec des événements ayant marqué l’histoire du Québec. Chaque événement est présenté à l’aide d’un court texte résumé, d’éléments visuels, d’une sélection bibliographique et d’une sélection de documents d’époque (sources primaires) pouvant comprendre des articles de journaux, des extraits de livres anciens, des textes manuscrits, des gravures, des cartes géographiques, etc. La plateforme retrace des événements qui se sont produits avant le XXIe siècle, pour permettre un certain recul sur l’histoire et son interprétation.</p>
  <p>Diffusée à partir de 2017 sous l’appellation La Ligne du temps du Québec, la plateforme est revue en 2021 afin d’améliorer l’expérience de navigation et  d’intégrer des éléments utiles aux acteurs des milieux de l’éducation. Le changement d’appellation qui adopte la forme plurielle (Lignes du temps…) a pour but d’ouvrir la plateforme à des sélections d’événements marquants choisis par les peuples autochtones du Québec.</p>
  <p>Si vous êtes membre d’un peuple autochtone et/ou enseignant(e) et que vous souhaitez contribuer à l’enrichissement de cette plateforme, n’hésitez pas à <a href="https://education.banq.qc.ca/nous-joindre/" target="_blank">communiquer avec nous!</a></p>
`;

const About = (props) => {
  useEffect(() => {
      gtmEvents.sendVirtualPageView(title, globalHistory.location.href.href, globalHistory.location.pathname);
  }, []);
  return <Generic location={props?.location} title={title} body={body} />;
};

export default About;
